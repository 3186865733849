import { ThemeColors, ThemeImages } from '@appconf/models';
import ProcivisBg from '@assets/images/ProcivisBackground.svg';
import { ThemeConfig as AntThemeConfig } from 'antd/lib/config-provider/context';

export const procivisThemeColors: ThemeColors = {
  neutral: '#ffffff',
  primary: '#ff3131',
  primaryVariant: '#ff3131',
  onPrimary: '#ffffff',
  onPrimaryVariant: '#ffffff',
  text: 'rgba(0, 0, 0, 0.85)',
  background: '#f0f2f5',
  componentBackground: '#ffffff',
  orgHighlight: 'orange',
  orgAdminHighlight: 'red',
};

export const procivisThemeImages: ThemeImages = {
  loginBgURI: ProcivisBg,
};

export const procivisThemeConfig: AntThemeConfig = {
  token: {
    borderRadius: 2,
    colorBgBase: '#f0f2f5',
    colorBgContainer: '#ffffff',
    colorBgElevated: '#ffffff',
    colorBgLayout: '#f0f2f5',
    colorHighlight: '#222222',
    colorInfo: '#ff3131',
    colorLink: '#ff3131',
    colorPrimary: '#ff3131',
    colorText: 'rgba(0, 0, 0, 0.85)',
  },
  components: {
    Menu: {
      itemSelectedBg: '#f5f5f5',
    },
    Descriptions: {
      colorSplit: '#f0f0f0',
    },
  },
};
