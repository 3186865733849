import { AppConfig, AppFlavor } from '@appconf/models';

import { procivisThemeColors, procivisThemeConfig, procivisThemeImages } from './theme';

const procivisConfig: AppConfig = {
  flavor: AppFlavor.procivis,
  release: process.env.REACT_APP_RELEASE ?? 'dev',
  mobileAppName: 'Procivis eID+',
  mobileAppNameShort: 'eID+',
  name: 'Desk',
  copyright: '© {date} - Procivis AG',
  sentry: {
    dsn: 'https://4d42f28b3b8842c8a82de43b716d5c25@o153694.ingest.sentry.io/5851084',
  },
  theme: {
    colors: procivisThemeColors,
    images: procivisThemeImages,
    config: procivisThemeConfig,
  },
  supportedLanguages: ['en', 'de', 'fr', 'it'],
};

export default procivisConfig;
